
















































































































































import {Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { GetCaoTypesAction } from '@/store/main/types';
import VButton from '@/components/VButton.vue';
import SubscriptionInfo from '@/interfaces/subscriptionInfo';
import * as accountTypes from '@/store/account/types';

@Component({
  components: { VButton },
})
export default class AccountCaos extends Vue {

  @Getter('subscriptionInfo', { namespace: 'account' }) private subscriptionInfo!: SubscriptionInfo;
  @Action('getCaoTypes', { namespace: 'main' }) private getCaoTypes!: GetCaoTypesAction;
  @Action('toggleCaos', { namespace: 'account' }) private toggleCaos!: accountTypes.ToggleCaosAction;
  @Action('toggleAgreementCaos', { namespace: 'account' })
  private toggleAgreementCaos!: accountTypes.ToggleAgreementCaosAction;
  @Action('subscriptionInfo', { namespace: 'account' })
  private getSubscriptionInfo!: accountTypes.SubscriptionInfoAction;

  private caoList: Array<{
    code: string, name: string, sbi: string, tool: boolean, api: boolean, deactivatedAt: string }> = [];
  private loadingCaosAndSubscription = false;
  private searchText = '';
  private pageLength = 20;
  private currentPage = 1;
  private selectedCao = { code: '', name: '' };
  private removingApiCao = false;
  private addingApiCao = false;
  private sortColumn: 'api' | 'tool' | 'name' | 'code' = 'api';
  private sortReverse = false;


  private created() {
    this.loadCaosAndSubscription();
  }

  private loadCaosAndSubscription(): void {
    this.loadingCaosAndSubscription = true;
    Promise.all([this.getCaoTypes({ online: true }), this.getSubscriptionInfo()]).then(([response, _]) => {
      this.caoList = response.map((elem) => {
        return {
        tool: this.subscriptionInfo.caosTool.has(elem.code),
        api: this.subscriptionInfo.caosAgreement.map((x) => x.code).includes(elem.code),
        deactivatedAt: this.subscriptionInfo.caosAgreement.find((x) => x.code === elem.code)?.deactivatedAt ?? '',
        ...elem,
        };
      });
      this.caoList = this.getSortedCaos();
    }).finally(() => {
      this.loadingCaosAndSubscription = false;
    });
  }

  private toggleToolCao(cao: { code: string, tool: boolean}) {
    this.toggleCaos({ cao_types: [cao] });
  }

  private removeApiCao(code: string) {
    this.removingApiCao = true;
    this.toggleAgreementCaos({ cao_types: [{ code, active: false }] }).finally(() => {
      this.removingApiCao = false;
      (this.$refs['account-agreement-remove-modal'] as any).hide();
      (this.$refs['account-agreement-success-modal'] as any).show();
      this.loadCaosAndSubscription();
    });
  }

  private addApiCao(code: string) {
    this.addingApiCao = true;
    this.toggleAgreementCaos({ cao_types: [{ code, active: true }] }).finally(() => {
      this.addingApiCao = false;
      (this.$refs['account-agreement-add-modal'] as any).hide();
      (this.$refs['account-agreement-success-modal'] as any).show();
      this.loadCaosAndSubscription();
    });
  }

  private setSortColumn(column: 'code' | 'name' | 'tool' | 'api') {
    if (this.sortColumn === column) {
      this.sortReverse = !this.sortReverse;
    } else {
      this.sortReverse = false;
      this.sortColumn = column;
    }
    this.caoList = this.getSortedCaos();
    this.currentPage = 1;
  }

  private getSortedCaos() {
    let result = [];
    if (this.sortColumn === 'api') {
      result = this.caoList.sort((a, b) => a.api ? -1 : (!b.api && a.tool) ? -1 : 1);
    } else if (this.sortColumn === 'tool') {
      result = this.caoList.sort((a, b) => a.tool ? -1 : (!b.tool && a.api) ? -1 : 1);
    } else {
      result = this.caoList.sort((a, b) => {
        return a[this.sortColumn] > b[this.sortColumn] ? 1 : -1;
      });
    }
    if (this.sortReverse) {
      result = result.reverse();
    }
    return result;
  }

  private toggleAllTool() {
      this.toggleCaos(
        { cao_types: this.searchedCaos.map((cao) => ({ tool: !this.allToolSelected, code: cao.code })) },
      ).finally(() => this.loadCaosAndSubscription());
  }

  get searchedCaos() {
    if (this.searchText.trim().length === 0) {
      return this.caoList;
    }
    return this.caoList.filter(
      (elem) => (elem.code + elem.name).toLowerCase().includes(this.searchText.trim().toLowerCase()),
    );
  }

  get allToolSelected() {
    return this.searchedCaos.every((cao) => cao.tool);
  }

  get paginatedCaos() {
    return this.searchedCaos.slice((this.currentPage - 1) * this.pageLength, this.currentPage * this.pageLength);
  }
}
